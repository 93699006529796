import { FC, createRef, memo } from 'react';
import styles from '../../../ExpensionMap.module.scss';
import cx from 'classnames';
import { useExpansionMapHelper } from '../../../helper';
import { Districts } from '../../../../../constants/districts';

export const NeustadtSued: FC = memo(() => {
  const ref = createRef<SVGTextElement>();
  const {
    district,
    expansionStatusClass,
    isSelected,
    handleClick,
    handleHover,
  } = useExpansionMapHelper({
    district: Districts.NeustadtSued,
    ref,
  });

  return (
    <>
      <g
        className={cx(styles.districtWrapper, {
          [styles.isSelected]: isSelected,
        })}
        id={district}
        onClick={handleClick}
        onMouseEnter={handleHover}
      >
        <path
          className={cx(styles[expansionStatusClass], styles.districtArea)}
          aria-label="Neustadt-Süd"
          d="M581.2,733.6L581.2,733.6L576,738l-18,6l-22-4h-14c-7.3-3.8-22.4-11-30-14l-10-16l-6-18l-18-10l2-12l-12-4 c-1-4.2-4.9-17.7-4-21.9l39,1.4c-0.2,7.5-5.6,32.9,40.7,52.6l6.3,7.9l39-8l4.2,15.5C575,720.5,577.7,727.2,581.2,733.6z"
        />
        <text
          className={styles.districtName}
          ref={ref}
          transform="translate(493.01 720.043)"
          textRendering="geometricPrecision"
        >
          Neustadt-Süd
        </text>
      </g>
    </>
  );
});
